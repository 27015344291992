import { Component, inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CmsService } from '../../services/cms.service';
import { CommonModule } from '@angular/common';
import { CalculatorSectionComponent } from '../calculator-section/calculator-section.component';
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'app-error-page',
  standalone:true,
  imports:[CommonModule,CalculatorSectionComponent],
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  selectedLanguage!: string;
  errorPageData: any;
  calculatorData:any;

  translate = inject(TranslateService);
  cmsService = inject(CmsService);
  seo = inject(SeoService);

  constructor() { }

  ngOnInit() {
    this.selectedLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe(res => {
      this.selectedLanguage = res.lang;
      this.preloadDataOnHomePage();
      this.getCmsContent();
    })
  }

  getCmsContent() {
    const page = 'error-page';
    const populate = 'deep';
    const locale = this.selectedLanguage;;
    const populateAndLocale = `?populate=${populate}&locale=${locale}`;

    this.cmsService.singlePageContent(page, populateAndLocale).subscribe((errPage: any) => {
      this.errorPageData = errPage;
      const seoData = errPage?.pluginSeo[0];
      this.seo.initializeSeo(seoData);
    })
  }

  preloadDataOnHomePage(){
    this.cmsService.data$.subscribe((res:any) => {
      this.calculatorData = res.calculatorSection;
    })
  }

}
