<div class="content">
<div class="error-page align-items-center flex-column">
  @if(errorPageData?.image?.data?.attributes?.url){  
  <div class="image-section flex align-items-center">
    <img [src]="errorPageData.image.data.attributes.url" [alt]="errorPageData.image.data.attributes.alternativeText">
  </div>
}
  @if(errorPageData?.title){
  <h1 class="font-calibre-70">{{errorPageData.title}}</h1>
  }
  @if(errorPageData?.description){
  <p class="font-univia-pro-18">{{errorPageData.description}}</p>
  }
  @if(errorPageData?.buttonText){
  <a href="/" class="error-page-button">{{errorPageData.buttonText}}</a>
  }
</div>
<div class="padding-bottom-150">
  <app-calculator-section [content]="calculatorData" [useCenter]="false"></app-calculator-section>
  </div>
</div>