@if(content){
<div #discoverSection class="content" [style.--bgColor]="content?.bgColor">
  @if(isMobile){
  <app-title-section class="mb-20" [content]="content?.discoverHeader" [useCenter]="false"></app-title-section>
  }
  <div class="image">
    <img class="image-border-radius" [ngSrc]="content?.image?.data[0]?.attributes?.url"
      [alt]="content?.image?.data[0]?.attributes?.alternativeText" [width]="content?.image?.data[0]?.attributes?.width" [height]="content?.image?.data[0]?.attributes?.height">
  </div>

  <div class="right-section">
    @if(!isMobile){
    <app-title-section [content]="content?.discoverHeader" [useCenter]="false"></app-title-section>
    }

    <div class="font-calibre-regular-20 width-85">
      @for(description of content.description; track description){
      <div [innerHTML]="description.children[0].text"></div>
      }
    </div>
    <app-button class="mb-35 width-85" [content]="content.button" [hideButton]="false"></app-button>


    @if(content.discoverNumber){
    <div class="inline-flex justify-between number-section width-85">
      @for(number of content?.discoverNumber; track number){
      <div class="number-container">
        <div class="font-calibre-70 number">{{ counters[number.label] | numberFormat }}</div>
        <div class="font-univia-pro-18 label">{{ number.label }}</div>
      </div>
      }
    </div>
    }
  </div>
</div>
}
