import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { AfterViewInit, Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { HeaderComponent } from "./pages/header/header.component";
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HomeComponent } from './pages/home/home.component';
import { FooterComponent } from "./pages/footer/footer.component";
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { register } from 'swiper/element/bundle';
import { AgenciesComponent } from './pages/agencies/agencies.component';
import { NeoCalculatorComponent } from './pages/neo-calculator/neo-calculator.component';
import { isPlatformBrowser } from '@angular/common';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { DomLocationProvider } from './providers/dom-location.provider';
import { CmsService } from './services/cms.service';
import { IntroductionComponent } from './components/introduction/introduction.component';
import { ContactSectionComponent } from './components/contact-section/contact-section.component';
import { ConsultAppointmentToolComponent } from './components/consult-appointment-tool/consult-appointment-tool.component';
import { ShareComponent } from './components/share/share.component';


register();

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [RouterOutlet, HeaderComponent, CommonModule, HttpClientModule, TranslateModule, HomeComponent, AboutUsComponent, FooterComponent, AgenciesComponent, NeoCalculatorComponent, IntroductionComponent, ContactSectionComponent, ConsultAppointmentToolComponent,ShareComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppComponent implements OnInit {
  title = 'neo';
  currentPage: string | null = null;
  currentFragment: string | null = null;
  private urlLanguagePrefixRegEx = new RegExp(/^(de.|en.|fr.|it.)/, "i");
  onlyRouter = false;
  introduction: any;
  contactSection: any;
  isVsrContactPage: boolean = true;


  constructor(
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
    private domLocation: DomLocationProvider,
    private cmsService: CmsService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateisVsrContactPage();
      }
    });
    this.translate.addLangs(["de", "en", "fr", "it"]);
    const urlLang = this.languageFromUrlOrDefault(this.domLocation.getHostName() || 'de')

    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('lang', urlLang);
      let lang = localStorage.getItem('lang');
      if (lang) {
        this.translate.use(lang);
      } else {
        lang = urlLang;
        localStorage.setItem('lang', lang);
        this.translate.use(lang);
      }
    } else if (typeof sessionStorage !== 'undefined') {
      // Fallback to sessionStorage if localStorage is not supported
      let lang = sessionStorage.getItem('lang');

      if (lang) {
        this.translate.use(lang);
      } else {
        lang = urlLang;
        sessionStorage.setItem('lang', lang);
        this.translate.use(lang);
      }
    } else {
      // If neither localStorage nor sessionStorage is supported
      console.log('Web Storage is not supported in this environment.');
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const urlParts = event.urlAfterRedirects.split('#');
        const currentPageWithFragment = urlParts[0];
        const currentPageParts = currentPageWithFragment.split('/');
        this.currentPage = currentPageParts[currentPageParts.length - 1];
        this.currentFragment = urlParts.length > 1 ? urlParts[1] : null;
        this.onlyRouter = currentPageWithFragment.includes('fcdietikon')

        if (event instanceof NavigationEnd && isPlatformBrowser(this.platformId)) {
          this.setBodyBackgroundColor(this.activatedRoute);
        }
      }
    });
  }
  ngOnInit(): void {
    let lang = localStorage.getItem('lang');
    this.translate.getTranslation(lang).subscribe((translationObj) => {  });
    this.getCalculatorData();
  }

  private updateisVsrContactPage(): void {

    const urlParts = this.router.url.split('/');

    this.isVsrContactPage = (
      (urlParts.length >= 3 &&
        urlParts[1].toLowerCase() === 'vorsorgerechner' &&
        (urlParts[2].toLowerCase() === 'contact' ||
          urlParts[2].toLowerCase().startsWith('contact-'))) ||
      urlParts[1].toLowerCase() === 'message-success'
    );

  }

  private setBodyBackgroundColor(route: ActivatedRoute): void {
    const footerRedirectLink = this.getFooterRedirectLink(route);
    if (footerRedirectLink) {
      document.body.style.backgroundColor = '#F4F4FA';
    } else {
      document.body.style.backgroundColor = '';
    }
  }

  private getFooterRedirectLink(route: ActivatedRoute): string | null {
    let component = route.snapshot.firstChild;
    while (component) {
      if (component.component && component.component === ImpressumComponent) {
        const footerRedirectLink = component.params['footerRedirectLink'];
        if (footerRedirectLink === 'impressum' || footerRedirectLink === 'datenschutz') {
          return footerRedirectLink;
        } else {
          return null;
        }
      }
      component = component.firstChild;
    }
    return null;
  }

  private languageFromUrlOrDefault(url: string): string {
    let language = 'de';
    if (url.match(this.urlLanguagePrefixRegEx)) {
      language = url.substring(0, 2);
    }
    return language;
  }

  getCalculatorData() {
    const page = 'vsr';
    const populate = 'deep';
    const locale = 'de';
    const populateAndLocale = `?populate=${populate}&locale=${locale}`;

    this.cmsService.singlePageContent(page, populateAndLocale).subscribe((data: any) => {
      this.introduction = data.Introduction;
      this.contactSection = data.Contact;
    });
  }

}
