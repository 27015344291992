<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-spin-clockwise" [fullScreen]="true">
    <p class="loading"> Loading... </p>
</ngx-spinner>
@if(content && content.showSection){

<div [id]="content.redirectFragment" class="contact-section" [ngClass]="{'contact-bg-color': currentPage === ':footerRedirectLink'}">
    <div class="contact-section-content">
        <div class="contact-section-tagline" [style.border-bottom]="taglineColor ? '2px ' + taglineColor + ' solid'  : '2px #FCAF17 solid'">
            <div class="contact-section-tagline-text">
                {{content?.SectionName}}</div>
        </div>
        <div class="contact-section-title">
            {{content?.Title}}</div>
        <div class="contact-section-text">
            {{content?.Text}}</div>
    </div>
    <div class="col-md-12">
        <div class="row contact-main-content">
            <div class="col-md-6 col-sm-12">
                <form [formGroup]="contactForm" id="myForm" class="contact-form">
                    <div class="contact-form-row">
                        <mat-select class="material-select" formControlName="salutation"
                            [placeholder]="content?.ContactForm?.Gender">
                            <mat-option *ngFor="let salutation of content?.Gender" [value]="salutation">{{
                                salutation.Gender }}</mat-option>
                        </mat-select>
                        <div class="col-w-33"></div>
                        <div class="col-w-33"></div>
                    </div>
                    <div class="contact-form-row second-row-inputs">
                        <input type="text" formControlName="lastName"
                            [placeholder]="content?.ContactForm?.Surname + ' *'" required class="contact-input-33"
                            [ngClass]="{ 'invalid-input': contactForm.get('lastName')?.invalid && (contactForm.get('lastName')?.dirty || contactForm.get('lastName')?.touched) }">
                        <input type="text" formControlName="firstName" [placeholder]="content?.ContactForm?.Name + ' *'"
                            required class="contact-input-33"
                            [ngClass]="{ 'invalid-input': contactForm.get('firstName')?.invalid && (contactForm.get('firstName')?.dirty || contactForm.get('firstName')?.touched) }">
                        <mat-form-field class="material-form-field"
                            [ngClass]="{ 'invalid-input': contactForm.get('birthdate')?.invalid && (contactForm.get('birthdate')?.dirty || contactForm.get('birthdate')?.touched) }">
                            <input matInput [matDatepicker]="picker"
                                [placeholder]="content?.ContactForm?.Birthdate + ' *'" formControlName="birthdate">
                            <mat-datepicker-toggle matIconSuffix [for]="picker">
                                <mat-icon matDatepickerToggleIcon>
                                    <img class="calendar-icon-img" src="../../../assets/icons/calendar.svg"
                                        alt="Calendar icon">
                                </mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="contact-form-row">
                        <input type="text" formControlName="address"
                            [placeholder]="content?.ContactForm?.StreetNumber + ' *'" class="contact-input-address"
                            [ngClass]="{ 'invalid-input': contactForm.get('address')?.invalid && (contactForm.get('address')?.dirty || contactForm.get('address')?.touched) }">
                    </div>
                    <div class="contact-form-row fourth-row-inputs">

                        <input type="text" [placeholder]="content?.ContactForm?.Zip + ' *'" matInput
                            [formControl]="regionFC" name="zip" [matAutocomplete]="auto" class="contact-input-zip"
                            [ngClass]="{ 'invalid-input': regionFC.invalid && (regionFC.dirty || regionFC.touched) }">

                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedRegion($event)"
                            [displayWith]="displayRegionFn.bind(this)">
                            <mat-option *ngFor="let region of filteredRegions | async" [value]="region">
                                {{region.PLZ}} - {{region.Ort}} ({{region.Gemeinde}})
                            </mat-option>
                        </mat-autocomplete>

                        <input type="text" formControlName="city" [placeholder]="content?.ContactForm?.City + ' *'"
                            required matInput readonly class="contact-input-city"
                            [ngClass]="{ 'invalid-input': contactForm.get('city')?.invalid && (contactForm.get('city')?.dirty || contactForm.get('city')?.touched) }">
                    </div>
                    <div class="contact-form-row fifth-row-inputs">
                        <input type="email" formControlName="email" [placeholder]="content?.ContactForm?.Email + ' *'"
                            required class="contact-input-33"
                            [ngClass]="{ 'invalid-input': contactForm.get('email')?.invalid && (contactForm.get('email')?.dirty || contactForm.get('email')?.touched) }">
                        <input type="tel" formControlName="phoneNumber"
                            [placeholder]="content?.ContactForm?.PhoneNumber + ' *'" required class="contact-input-33"
                            [ngClass]="{ 'invalid-input': contactForm.get('phoneNumber')?.invalid && (contactForm.get('phoneNumber')?.dirty || contactForm.get('phoneNumber')?.touched) }">
                        <mat-select class="material-select" formControlName="language"
                            [placeholder]="content?.ContactForm?.Languages + ' *'" required class="material-select"
                            [ngClass]="{ 'invalid-input': contactForm.get('language')?.invalid && (contactForm.get('language')?.dirty || contactForm.get('language')?.touched) }">
                            <mat-option *ngFor="let language of content?.Languages" [value]="language">{{
                                language.Language }}</mat-option>
                        </mat-select>
                    </div>
                    <textarea formControlName="message" [placeholder]="content?.ContactForm?.Message + ' *'"
                        class="contact-input-textarea"
                        [ngClass]="{ 'invalid-input': contactForm.get('message')?.invalid && (contactForm.get('message')?.dirty || contactForm.get('message')?.touched) }"></textarea>
                    <button class="contact-btn-sendmail" (click)="sendMail(); contactForm.markAllAsTouched() ">
                        <div class="contact-btn-text">
                            {{content?.SendMailButton}}
                        </div>
                    </button>
                </form>
            </div>
            <!-- <div class="col-md-2">
            </div> -->
            <div class="col-md-4 col-sm-12 map-column">
                <div class="contact-map-section">
                    <div class="contact-address">
                        <div class="contact-address-content">
                            <div class="contact-address-content-title">
                                {{content?.Address?.Title}}</div>
                        </div>
                        <div class="contact-address-content-container">
                            <span class="contact-address-name">
                                {{content?.Address?.Name}}
                                <br /></span>
                            <span class="contact-address-details">
                                {{content?.Address?.Street}}
                                <br />
                                {{content?.Address?.ZIP}}
                                <br />{{content?.Address?.PhoneNumber}}</span>
                        </div>
                    </div>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1606.2081294464908!2d8.495749477383578!3d47.38639740585354!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47900bcb22e27d09%3A0xc190d1e148a344ee!2sNeosana%20AG!5e0!3m2!1sen!2smk!4v1707382330223!5m2!1sen!2smk"
                        width="100%" height="364" class="contact-map" allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>

                </div>
            </div>
        </div>
    </div>
</div>
}