import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CmsService } from '../../services/cms.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { DomLocationProvider } from '../../providers/dom-location.provider';
import { Location } from '@angular/common';


@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, HttpClientModule, TranslateModule, RouterModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  allMenuItems: any;
  subMenuItems: any = {};
  subMenuItemsRedirects: any = {};
  menuItems: any = [];
  selectedSubMenuIndex: number | null = null;
  languages = ['en', 'de', 'fr', 'it'];
  selectedLanguage: string = 'de';
  isLanguageDropdownOpen: boolean = false;
  private urlLanguagePrefixRegEx = new RegExp(/^(de.|en.|fr.|it.)/, 'i');
  isMobileMenuOpen: boolean = false;
  subMenuItemsUnderLineColors: any = {};
  content: any;
  underLineColor: any;
  activeSubMenu: string | null = null;
  hoveredMenuIndex: number | null = null;
  @Input() currentPage: string | null = null;
  @Input() currentFragment: string | null = null;
  activePage = "/home";
  isMenuOpen: boolean = false;
  closeMenuTimeout: any;
  @ViewChild('menuContainer') menuContainer!: ElementRef;
  isMouseOverMenu: boolean = false;
  isMouseOverSubMenu: boolean = false;
  headerLogo : string = '';


  constructor(
    private router: Router,
    private cmsService: CmsService,
    public translate: TranslateService,
    private angularLocation: Location,
    private domLocation: DomLocationProvider,
    // private langService: SharedLangServiceService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.closeMenuAndLanguage();
      }
    });
  }

  ngOnInit() {
    this.selectedLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe((res) => {
      this.selectedLanguage = res.lang;
      this.getMenuItems();
    });
    this.setActiveSubMenu();
  }

  assignLang(lang: string): void {
    // this.langService.triggerLangChange(lang);
  }

  isMenuItemActive(redirectLink: string): boolean {
    return this.activePage === redirectLink || window.location.href.includes(redirectLink);
  }

  isActiveMenuItem(menuItem: any): boolean {
    if (menuItem.title === 'Dienstleistungen') {
      return this.currentPage === menuItem.redirectLink || this.subMenuItemsRedirects[menuItem.title]?.includes(this.currentPage);
    } else {
      return this.currentPage === menuItem.redirectLink;
    }
  }

  isDienstleistungenOrServices(title: string): boolean {
    return title === 'Dienstleistungen' || title === 'Services';
  }

  getMenuItems() {
    let populateString = `?populate=deep&locale=${this.selectedLanguage}`;
    this.cmsService.singlePageContent('menu', populateString).subscribe((res: any) => {
      this.underLineColor = res.underLineColor;
      if (res.menuItems) {
        this.allMenuItems = res.menuItems;
        this.fillSubmenuItems();
        this.cmsService.setAllMenuItems(this.allMenuItems);
        this.setActiveSubMenu();
      }
      this.headerLogo = res.headerLogo.data.attributes.url;
    });
  }

  toggleSubMenu(index: number): void {
    this.isLanguageDropdownOpen = false;
    if (this.selectedSubMenuIndex === index) {
      this.selectedSubMenuIndex = null;
    } else {
      this.selectedSubMenuIndex = index;
    }
    setTimeout(() => {
      this.setSubMenuBackgroundHeight();
    });
  }

  isMobileDevice() {
    return window.innerWidth <= 880;
  }


  handleMenuItemHover(i: number, menuItem: any) {
    this.isLanguageDropdownOpen = false;
    this.isMouseOverMenu = true;
    clearTimeout(this.closeMenuTimeout);
    if (menuItem.subMenuItem.length > 0) {
      this.selectedSubMenuIndex = i;
      this.hoveredMenuIndex = i;
      setTimeout(() => {
        this.setSubMenuBackgroundHeight();
      });
    } else {
      this.selectedSubMenuIndex = null;
    }
  }


  onMouseEnterMenu(): void {
    this.isMouseOverMenu = true;
    clearTimeout(this.closeMenuTimeout);
  }

  onMouseLeaveMenu(): void {
    if (this.isMobileDevice()) {
      return;
    }
    if (!this.isMouseOverSubMenu) {
      this.isMouseOverMenu = false;
      this.startCloseTimeout();
    }
  }

  navigateTo(menuItem : any){
    this.redirectTo(menuItem.redirectLink);
  }



  handleMenuItemClick(i: number, menuItem: any) {
    if (menuItem.subMenuItem.length < 1) {
      this.redirectTo(menuItem.redirectLink);
    } else {
      this.toggleSubMenu(i);
    }
  }

  handleSubMenuItemClick(mainMenuItem: string, fragment: string, subMenuLink: string, subMenuTitle: string, underLineColor: string) {
    if (fragment) {
      this.currentPage = mainMenuItem;
      this.currentFragment = fragment;
      window.location.href = `${mainMenuItem}#${fragment}`;
    } else {
      this.currentPage = subMenuLink;
      this.currentFragment = null;
      window.location.href = `${subMenuLink}`;
    }
    this.activeSubMenu = subMenuTitle;
    this.underLineColor = underLineColor;
    this.setActiveSubMenu();
  }


  toggleMobileMenu(): void {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
    this.isLanguageDropdownOpen = false;
    document.body.style.overflowY = this.isMobileMenuOpen ? 'hidden' : 'visible';
    this.closeMenu();
  }

  private closeTimeout: any;

  private startCloseTimeout() {
    if (this.isMobileDevice()) {
      return;
    }
    this.closeMenuTimeout = setTimeout(() => {
      if (!this.isMouseOverMenu && !this.isMouseOverSubMenu) {
        this.selectedSubMenuIndex = null;
        this.hoveredMenuIndex = null;
      }
    }, 100);
  }


  setActiveSubMenu() {
    for (const [menuTitle, subMenu] of Object.entries(this.subMenuItems)) {
      for (const subMenuItem of subMenu as any[]) {
        if (this.isMenuItemActive(subMenuItem.redirectLink)) {
          this.activeSubMenu = subMenuItem.title;
          this.underLineColor = subMenuItem.underLineColor;
          return;
        }
      }
    }
  }

  onMouseEnterSubMenu(): void {
    this.isMouseOverSubMenu = true;
    clearTimeout(this.closeMenuTimeout);
  }

  onMouseLeaveSubMenu(): void {
    if (this.isMobileDevice()) {
      return;
    }
    this.isMouseOverSubMenu = false;
    if (!this.isMouseOverMenu) {
      this.startCloseTimeout();
    }
  }

  private isMouseOverElement(selector: string): boolean {
    if (!this.currentMouseEvent) return false;

    const element = document.querySelector(selector) as HTMLElement;
    if (!element) return false;

    const rect = element.getBoundingClientRect();
    const mouseX = this.currentMouseEvent.clientX;
    const mouseY = this.currentMouseEvent.clientY;

    return (
      mouseX >= rect.left &&
      mouseX <= rect.right &&
      mouseY >= rect.top &&
      mouseY <= rect.bottom
    );
  }


  onMouseEnterSubMenuBackground(): void {
    clearTimeout(this.closeTimeout);
    this.hoveredMenuIndex = this.selectedSubMenuIndex;
  }

  onMouseLeaveSubMenuBackground(): void {
    this.startCloseTimeout();
  }

  closeMenu(): void {
    this.selectedSubMenuIndex = null;
    this.hoveredMenuIndex = null;
  }

  changeLanguage(language: string, considerLanguageDomain: boolean = true) {
    this.isLanguageDropdownOpen = false;
    this.selectedLanguage = language;
    localStorage.setItem('lang', language);
    this.translate.use(this.selectedLanguage);
    if (considerLanguageDomain) {
      this.handleLanguageDomain();
    }
  }

  toggleLanguageDropdown(): void {
    this.isLanguageDropdownOpen = !this.isLanguageDropdownOpen;
    this.isMobileMenuOpen = false;
    this.selectedSubMenuIndex = null;
    document.body.style.overflowY = this.isMobileMenuOpen ? 'hidden' : 'visible';

  }

  handleLanguageDomain(): void {
    const hostname = this.domLocation.getHostName();
    const isLangUrl = hostname.match(this.urlLanguagePrefixRegEx);
    const correctLangUrl =
      isLangUrl && hostname.indexOf(`${this.selectedLanguage}.`) >= 0;
    if (correctLangUrl) {
      return;
    }
    const p = this.domLocation;
    if (
      p.getHostName() !== 'localhost' &&
      p.getHostName() !== 'dev.neo.swiss' &&
      p.getHostName() !== 'www.neo.swiss'
    ) {
      let domain = p.getHostName();
      if (!isLangUrl) {
        if (this.selectedLanguage !== 'de') {
          domain = `${this.selectedLanguage}.${domain}`;
        }
      } else {
        if (this.selectedLanguage !== 'de') {
          domain = domain.replace(
            this.urlLanguagePrefixRegEx,
            `${this.selectedLanguage}.`
          );
        } else {
          domain = 'neo.swiss';
        }
      }
      const redirectUrl = `${p.getProtocol()}//${domain}${this.angularLocation.path()}`;
      p.setHref(redirectUrl);
    }
  }

  redirectTo(page: string) {
    if (page) {
      window.location.href = `/${page}`;
      this.activePage = page;
      this.isMobileMenuOpen = false;
    }
  }


  @HostListener('document:mousemove', ['$event'])
  onDocumentMouseMove(event: MouseEvent) {
    if (this.isMobileDevice()) {
      return;
    }
    const isInsideMenuContainer = this.isMouseInsideElement(event, '.menu-container');
    const isInsideSubMenuBackground = this.isMouseInsideElement(event, '.sub-menu-background');

    if (!isInsideMenuContainer && !isInsideSubMenuBackground) {
      this.closeMenu();
    }
  }

  private isMouseInsideElement(event: MouseEvent, selector: string): boolean {
    const element = document.querySelector(selector) as HTMLElement;
    return element && element.contains(event.target as Node);
  }

  private currentMouseEvent: MouseEvent | null = null;

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.menuContainer) {
      const rect = this.menuContainer.nativeElement.getBoundingClientRect();

      const buffer = 35;

      const isOverMenu = (
        event.clientX >= (rect.left - buffer) &&
        event.clientX <= (rect.right + buffer) &&
        event.clientY >= (rect.top - buffer) &&
        event.clientY <= (rect.bottom + buffer)
      );

      if (!isOverMenu && !this.isMouseOverSubMenu) {
        this.isMouseOverMenu = false;
        this.startCloseTimeout();
      } else if (isOverMenu) {
        this.isMouseOverMenu = true;
        clearTimeout(this.closeMenuTimeout);
      }
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    this.isLanguageDropdownOpen = false;
    this.selectedSubMenuIndex = null;
    if (this.isMobileMenuOpen) {
      this.toggleMobileMenu();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.selectedSubMenuIndex = null;
    this.isLanguageDropdownOpen = false;
    if (window.innerWidth < 880) {
      this.isMobileMenuOpen = false;
      document.body.style.overflowY = 'visible';
    }
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: MouseEvent) {
    const isClickedInsideSubMenu = (event.target as HTMLElement).closest('.menu-items');
    const isClickedInsideLanguageDropdown = (event.target as HTMLElement).closest('.language');
    const isClickedInsideSubMenuBackground = (event.target as HTMLElement).closest('.sub-menu-background');

    if (!isClickedInsideSubMenu && !isClickedInsideLanguageDropdown && !isClickedInsideSubMenuBackground) {
      this.closeMenuAndLanguage();
    }
  }

  @HostListener('document:mouseleave', ['$event'])
  onMouseLeave(event: MouseEvent) {
    if (this.isMobileDevice()) {
      return;
    }
    this.closeMenu();
  }


  closeMenuAndLanguage() {
    this.selectedSubMenuIndex = null;
    this.isLanguageDropdownOpen = false;
  }

  @ViewChild('subItems') subItemsRef!: ElementRef;
  @ViewChild('subMenuBackground') subMenuBackgroundRef!: ElementRef;



  setSubMenuBackgroundHeight() {
    if (this.subItemsRef && this.subMenuBackgroundRef && this.selectedSubMenuIndex !== null) {
      const defaultHeight = 200;
      const subItemsHeight = this.subItemsRef.nativeElement.offsetHeight;
      const totalHeight = subItemsHeight + defaultHeight;
      this.subMenuBackgroundRef.nativeElement.style.height = totalHeight + 'px';
    }
  }


  handleMenuItemLeave() {
    if (this.isMobileDevice()) {
      return;
    }
    this.startCloseTimeout();
  }

  fillSubmenuItems() {
    for (let menuItem of this.allMenuItems) {
      this.subMenuItems[menuItem.title] = menuItem.subMenuItem;
      this.subMenuItemsRedirects[menuItem.title] = menuItem.subMenuItem.map((x: any) => x.redirectLink);
      this.subMenuItemsUnderLineColors[menuItem.title] = menuItem.subMenuItem.map((x: any) => x.underLineColor);
    }
  }
}