.w-100.h-auto swiper-container swiper-slide {
  margin-top: 30px !important;
}

.team-section {
  width: 100%;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 100px;
  padding-bottom: 50px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.team-section-header {
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  display: inline-flex;
  flex-wrap: wrap;
}

.team-section-title-content {
  width: 900px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 25px;
  display: inline-flex;
}

.team-section-tagline {
  padding-bottom: 10px;
  border-bottom: 2px #5BC9E9 solid;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  display: inline-flex;
}

.team-section-text {
  color: #2F3F60;
  font-size: 14px;
  font-family: 'Univia Pro Regular', sans-serif;
  ;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 7px;
  word-wrap: break-word;
}

.team-section-title {
  width: 80%;
  max-width: 800px;
  color: #2F3F60;
  font-size: 36px;
  font-family: 'Univia Pro Regular', sans-serif;
  font-weight: 400;
  line-height: 38px;
  word-wrap: break-word;
}

.team-navigation-buttons {
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.team-navigation-icon {
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.team-member-card {
  width: 100%;
  height: 70%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
}

.image-container {
  width: auto;
  height: 450px;
  overflow: hidden;
  position: relative;
  border-radius: 4px 4px 120px 4px;
}

.team-member-image {
  width: auto;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 4px 4px 0 0;
}


.team-member-content {
  height: 77px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  display: flex
}

.team-member-title {
  align-self: stretch;
  color: #2F3F60;
  font-size: 20px;
  font-family: 'Univia Pro Regular', sans-serif;
  font-weight: 400;
  line-height: 30px;
  word-wrap: break-word
}

.team-member-position-text {
  color: #2F3F60;
  font-size: 13px;
  font-family: 'Univia Pro Regular', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 14px;
  letter-spacing: 7px;
  word-wrap: break-word
}

.prev-next {
  height: 45px;
  width: 45px;
  background-color: var(--btnBgColor);
  border-radius: 50%;
}

.prev-next:hover {
  background-color: white;
  border: 2px solid var(--btnBgColor);
}

.button-component {
  .component-btn {
    display: flex;
    padding: 10px;
    justify-content: center;
    font-weight: 600;
    color: var(--color);
    align-items: center;
    gap: 8px;
    border-radius: 60px;
    border: 2px solid var(--btnBgColor);
    background-color: var(--btnBgColor);

    transition: 200ms ease-in-out;

    &:hover {
      background-color: var(--color-white);
      color: var(--color);
      border: 2px solid var(--btnBgColor);

      img {

        fill: var(--hoverColor);
      }
    }
  }

  .icon {
    max-width: 20px;
    max-height: 20px;
  }
}

.index-zero {
  background-color: white;
  border: 2px solid var(--btnBgColor);
}

@media (max-width: 549px) {
  .image-container {
    height: 450px;
  }

  .team-member-card {
    height: fit-content;
  }
}

@media (max-width: 768px) {
  .team-section {
    padding: 50px 15px;
    gap: 30px;
  }

  .team-section-text {
    font-size: 13px;
  }

  .team-section-title {
    font-size: 26px;
    line-height: 40px;
    width: 100%;
  }

  .team-section-header {
    gap: 20px;
  }

}

@media (max-width: 1377px) {
  .team-navigation-buttons {
    width: 100%;
    justify-content: flex-end
  }
}

@media (min-width: 1700px) {
  .image-container {
    width: 90%;
  }
}

@media (min-width: 2100px) {
  .team-section {
    padding: 5vw 2vw;
    gap: 2vw;
  }

  .team-section-header {
    gap: 1vw;
  }

  .team-section-title-content {
    width: 50vw;
    gap: 1.5vw;
  }

  .team-section-tagline {
    padding-bottom: 0.5vw;
    border-bottom-width: 0.1vw;
    gap: 0.3vw;
  }

  .team-section-text {
    font-size: 1vw;
    line-height: 0.8vw;
    letter-spacing: 0.4vw;
  }

  .team-section-title {
    font-size: 2.58vw;
    line-height: 2.9vw;
    max-width: 40vw;
  }

  .team-navigation-buttons,
  .team-navigation-icon {
    gap: 0.5vw;
  }

  .team-member-card {
    gap: 1vw;
  }

  .arrow {
    max-width: 200%;
    width: 1.4vw;
    height: 1.4vw;
  }

  .image-container {
    height: 35vw;
    border-radius: 0.2vw 0.2vw 6vw 0.2vw;
    width: 100%;
  }

  .team-member-image {
    border-radius: 0.2vw 0.2vw 0 0;
  }

  .team-member-content {
    gap: 0.8vw;
    margin-bottom: 2vw;
  }

  .team-member-title {
    font-size: 1.5vw;
    line-height: 2.5vw;
  }

  .team-member-position-text {
    font-size: 1vw;
    line-height: 0.8vw;
    letter-spacing: 0.4vw;
  }

  .prev-next {
    height: 3.5vw;
    width: 3.5vw;
  }

  .button-component .component-btn {
    padding: 0.5vw;
    gap: 0.5vw;
    border-radius: 3vw;
    border-width: 0.1vw;
  }

  .button-component .icon {
    max-width: 1vw;
    max-height: 1vw;
  }
}